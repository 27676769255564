<template>
  <div class="textBox d-flex">
    <v-card
      tile
      color="transparent"
      height="50%"
      :class="screenType"
      width="auto"
    >
      <v-row class="ma-0 pa-0">
        <v-col :cols="widthCol">
          <v-card-text class="pt-0 text-p">1/1</v-card-text>
          <v-card-title :class="textStyle[0]"> ABOUT. </v-card-title>
          <v-card-text :class="textStyle[1]">
            Hidden in the old tuinbouw school in the UNESCO world heritage site
            in Frederiksoord (Netherlands) work the duo
            <strong>Kim Van Den Belt</strong> and <strong>Joshua Kelly</strong>.
            Combined, they have experience at the intersection of Design, Art,
            and Science, and have worked on projects ranging from consumer to
            enterprise; hardware, software and service; and speculative to
            interactive.</v-card-text
          >
        </v-col>
        <v-col :cols="partnerCol[0]">
                <v-img
                eager
                class="aligin-self-center mb-16"
                width="100%"
                src="@/assets/kj-min.webp"
              />
          <v-card-title :class="textStyle[1]">COLLABORATORS.</v-card-title>
          <v-card class="d-flex flex-wrap flex-md-row elevation-0">
            <v-card
              :width="partnerCol[1]"
              class="elevation-0 pa-4 d-flex"
              v-for="index in 10"
              :ref="index"
              :key="index"
            >
              <v-img
                eager
                class="aligin-self-center"
                width="100%"
                :src="require(`@/assets/partners/${index}.png`)"
              />
            </v-card>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
//   @ is an alias to /src

export default {
  name: "Home",
  metaInfo: {
    title: "4F.STUDIO",
    titleTemplate: "%s | About",
    htmlAttrs: {
      lang: "en-US",
    },
    meta: [
      { charset: "utf-8" },
      {
        name: "description",
        vmid: "description",
        content:
          "Experience at the intersection of Design, Art, and Science, and have worked on projects ranging from consumer to enterprise; hardware, software and service; and speculative to interactive.",
      },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
    ],
  },
  computed: {
    screenType() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "align-self-top elevation-0 mt-16 pt-8";
        case "sm":
          return "align-self-top elevation-0 mt-16 pt-16";
        case "md":
          return "align-self-center elevation-0 ml-16";
        case "lg":
          return "align-self-center elevation-0 ml-16";
        case "xl":
          return "align-self-center elevation-0 ml-16";
      }
      return 6;
    },
    textStyle() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return ["pt-2", "pt-0", "pt-5 text-p white--text"];
        case "sm":
          return [
            "pt-2 font-weight-bold text-h5",
            "pt-0 text-p",
            "pt-5 text-p white--text",
          ];
        case "md":
          return [
            "pt-2 font-weight-bold text-h5",
            "pt-0 text-p black--text",
            "pt-5 text-p white--text",
          ];
        case "lg":
          return [
            "pt-2 font-weight-bold text-h5",
            "pt-0 text-p",
            "pt-5 text-p white--text",
          ];
        case "xl":
          return [
            "pt-2 font-weight-bold text-h4",
            "pt-0 text-h6",
            "pt-5 text-h6 white--text",
          ];
      }
      return 6;
    },
    widthCol() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 12;
        case "sm":
          return 6;
        case "md":
          return 4;
        case "lg":
          return 4;
        case "xl":
          return 4;
      }
      return 6;
    },
    partnerCol() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return [12, "50%"];
        case "sm":
          return [12, "50%"];
        case "md":
          return [7, "20%"];
        case "lg":
          return [7, "20%"];
        case "xl":
          return [7, "20%"];
      }
      return 6;
    },
  },
};
</script>
<style scoped>
.textBox {
  height: 100vh;
}
</style>

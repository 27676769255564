<template>
  <div class="textBox d-flex">
    <v-card
      tile
      color="transparent"
      height="50%"
      :class="screenType"
      width="auto"
    >
      <v-row class="ma-0 pa-0">
        <v-col :cols="widthCol">
          <v-card-text class="pt-0 text-p">1/1</v-card-text>
          <v-card-title :class="textStyle[0]"> EXHIBITIONS. </v-card-title>
          <v-card-text :class="textStyle[1]">
            2022 &nbsp;&nbsp;&nbsp;&nbsp; Microbial Garden, NL Space Campus, Noordwijk, NL
          </v-card-text>
          <v-card-text :class="textStyle[1]">
            2022 &nbsp;&nbsp;&nbsp;&nbsp; The Lighthouse & Kaia, Manifesations, Veem Gebouw, Dutch Design Week, Eindhoven, NL
          </v-card-text>
          <v-card-text :class="textStyle[1]">
            2022 &nbsp;&nbsp;&nbsp;&nbsp; CK3, Klockebouw, Dutch Design Week, Eindhoven, NL
          </v-card-text>
          <v-card-text :class="textStyle[1]">
            2022 &nbsp;&nbsp;&nbsp;&nbsp; The Lighthouse & Microbial garden, European Space agency, ESTEC, Noordwijk, NL
          </v-card-text>
          <v-card-text :class="textStyle[1]">
            2022 &nbsp;&nbsp;&nbsp;&nbsp; Microbial garden, Weekend van de wetenschap, Forum, Groningen, NL
          </v-card-text>
          <v-card-text :class="textStyle[1]">
            2022 &nbsp;&nbsp;&nbsp;&nbsp; Microbial garden, Utopia Island, Floriade, Almere, NL
          </v-card-text>
          <v-card-text :class="textStyle[1]">
            2022 &nbsp;&nbsp;&nbsp;&nbsp; Light challenge pavillion, Floriade, Almere, NL
          </v-card-text>
          <v-card-text :class="textStyle[1]">
            2022 &nbsp;&nbsp;&nbsp;&nbsp; The Lighthouse, Luna Lights, Leeuwarden, NL
          </v-card-text>
          <v-card-text :class="textStyle[1]">
            2021 &nbsp;&nbsp;&nbsp;&nbsp; The Luminarium, BioArt Laboratories, Eindhoven, NL
          </v-card-text>
          <v-card-text :class="textStyle[1]">
            2021 &nbsp;&nbsp;&nbsp;&nbsp; Kaia, BioArt Laboratories, Eindhoven, NL
          </v-card-text>
        <v-card-text :class="textStyle[1]">
            2021 &nbsp;&nbsp;&nbsp;&nbsp; Kaia, Museum De Lakenhal, Leiden, NL
          </v-card-text>
         <v-card-text :class="textStyle[1]">
            2019 &nbsp;&nbsp;&nbsp;&nbsp; Arboretum Digitalus, BioArt Laboratories, Eindhoven, NL
          </v-card-text>
        </v-col>
      </v-row>
        <v-col :cols="widthCol">
          <v-card-title :class="textStyle[0]"> AWARDS. </v-card-title>
          <v-card-text :class="textStyle[1]">
            2022 &nbsp;&nbsp;&nbsp;&nbsp; The Lighthouse, Young talent award, Manifesations, Veem Gebouw, Dutch Design Week, NL
          </v-card-text>
            <v-card-text :class="textStyle[1]">
            2022 &nbsp;&nbsp;&nbsp;&nbsp; Lighthouse, 1st place, Light challenge, NL
          </v-card-text>
        </v-col>
    </v-card>
  </div>
</template>

<script>
//   @ is an alias to /src

export default {
  name: "Home",
  metaInfo: {
    title: "4F.STUDIO",
    titleTemplate: "%s | Exhibitions",
    htmlAttrs: {
      lang: "en-US",
    },
    meta: [
      { charset: "utf-8" },
      {
        name: "description",
        vmid: "description",
        content:
          "Exhibited at BioArt Laboratories, BASE Milano, Luna Light Festival, Floriade, Museum Lackenhal, Utrecht Down under,Manifestations",
      },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
    ],
  },
  computed: {
    screenType() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "align-self-top elevation-0 mt-16 pt-8";
        case "sm":
          return "align-self-top elevation-0 mt-16 pt-16";
        case "md":
          return "align-self-center elevation-0 ml-16";
        case "lg":
          return "align-self-center elevation-0 ml-16";
        case "xl":
          return "align-self-center elevation-0 ml-16";
      }
      return 6;
    },
    textStyle() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return ["pt-2", "pt-0", "pt-5 text-p white--text"];
        case "sm":
          return [
            "pt-2 font-weight-bold text-h5",
            "pt-0 text-p",
            "pt-5 text-p white--text",
          ];
        case "md":
          return [
            "pt-2 font-weight-bold text-h5",
            "pt-0 text-p black--text",
            "pt-5 text-p white--text",
          ];
        case "lg":
          return [
            "pt-2 font-weight-bold text-h5",
            "pt-0 text-p",
            "pt-5 text-p white--text",
          ];
        case "xl":
          return [
            "pt-2 font-weight-bold text-h4",
            "pt-0 text-h6",
            "pt-5 text-h6 white--text",
          ];
      }
      return 6;
    },
    widthCol() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 12;
        case "sm":
          return 12;
        case "md":
          return 12;
        case "lg":
          return 12;
        case "xl":
          return 12;
      }
      return 6;
    },
  },
};
</script>
<style scoped>
.textBox {
  height: 100vh;
}
</style>

<template>
  <div class="textBox d-flex">
    <v-card
      tile
      color="transparent"
      height="50%"
      :class="screenType"
      width="auto"
    >
      <v-row class="ma-0 pa-0">
        <v-col :cols="widthCol">
          <v-card-text class="pt-0 text-p">1/1</v-card-text>
          <v-card-title :class="textStyle[0]"> PUBLICATIONS. </v-card-title>
          <ul class="list">
            <li v-for="(child, index) in publications" :key="index">
              <v-card-text>
                <a class="link" :href="child.link" target="_blank">
                  {{ child.title }}
                </a>
              </v-card-text>
            </li>
          </ul>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
//   @ is an alias to /src
import pageData from "@/data/data.js";
export default {
  name: "Home",
  metaInfo: {
    title: "Publications",
    titleTemplate: "%s | 4F.STUDIO in the media",
  },
  computed: {
    screenType() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "align-self-top elevation-0 mt-16 pt-8";
        case "sm":
          return "align-self-top elevation-0 mt-16 pt-16";
        case "md":
          return "align-self-center elevation-0 ml-16";
        case "lg":
          return "align-self-center elevation-0 ml-16";
        case "xl":
          return "align-self-center elevation-0 ml-16";
      }
      return 6;
    },
    publications() {
      let publicationData = pageData["publications"];
      return publicationData;
    },
    textStyle() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return ["pt-2", "pt-0", "pt-5 text-p white--text"];
        case "sm":
          return [
            "pt-2 font-weight-bold text-h5",
            "pt-0 text-p",
            "pt-5 text-p white--text",
          ];
        case "md":
          return [
            "pt-2 font-weight-bold text-h5",
            "pt-0 text-p black--text",
            "pt-5 text-p white--text",
          ];
        case "lg":
          return [
            "pt-2 font-weight-bold text-h5",
            "pt-0 text-p",
            "pt-5 text-p white--text",
          ];
        case "xl":
          return [
            "pt-2 font-weight-bold text-h4",
            "pt-0 text-h6",
            "pt-5 text-h6 white--text",
          ];
      }
      return 6;
    },
    widthCol() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 12;
        case "sm":
          return 6;
        case "md":
          return 4;
        case "lg":
          return 4;
        case "xl":
          return 4;
      }
      return 6;
    },
    partnerCol() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return [12, "50%"];
        case "sm":
          return [12, "50%"];
        case "md":
          return [7, "20%"];
        case "lg":
          return [7, "20%"];
        case "xl":
          return [7, "20%"];
      }
      return 6;
    },
  },
};
</script>
<style scoped>
.textBox {
  height: 100vh;
}
.list {
  list-style-type: none;
  padding: 0;
}

.list li {
  list-style-type: none;
  width: auto;
}
.link {
  list-style-type: none;
  color: black;
}
</style>

import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    infoPanel:false,
  },
  mutations: {
    setPanelState(state,val){
      state.infoPanel = val
  }
}
})
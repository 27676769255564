let data = {
    work:{
        0:{
            title:'4F.STUDIO',
            year: '',
            designer: '',
            link: '',
            image:'',
            text:'4F.STUDIO is a future-thinking design practice that aims to combine nature and technology in a responsible way. Working independently and cooperatively with companies and academic institutions, they create installations, mixed-media sculptures, interactive experiences and sustainable products',
        },
        1:{
            title:'Microbial Garden',
            year: 'Current',
            designer: '4F.STUDIO',
            link: 'none',
            text:'Speculative food tasting event at Floriade in collaboration with SIA and Hanze Hogeschnool of Applied Science. We will be testing the output of closed loop microbial bioreactors in various recipes in order to elicit consumer perception on the future of microbial based foods.',
            image:'microbialGarden.webp'
        },
        2:{
            title:'DE PROEF.',
            year: 'Current',
            designer: '4F.STUDIO',
            link: 'https://issuu.com/4f.studio/docs/de_proef-plan-min',
            text:"DE PROEF, a community-led association, has taken responsibility for the buildings and gardens of the former Gerard Adriaan van Swieten Horticultural School. DE PROEF's mission is to promote regenerative agricultural practices, provide hands-on education and promote community involvement through the arts and culture. Its goal is to inspire and educate the community about the importance of protecting and conserving nature through education, art and cultural practices.",
            image:'deproef.webp'
        },
        3:{
            title:'CK3',
            year: 'Current',
            designer: '4F.STUDIO',
            link: 'none',
            text:'Design and construction of a short circular greenhouse in collaboration with Hanze Hogeschnool of Applied Science and the Circulaire Versnellers. The greenhouse will be built in the old tuinbouw school greenhouses at the UNESCO world hertiage site Fredereksoord in Drenthe.',
            image:'frederiksoord.webp'
        },
        4:{
            title:'The Lighthouse',
            year: '2022',
            designer: '4F.STUDIO',
            link: 'https://www.4f.studio/lighthouse',
            text:'This first prototype of the Lighthouse collection is home to a few billion Cyanobacteria, thriving due to photosynthesis. This item of public jewellery, gives a secondary function to public night light by allowing the Cyanobacteria to continue their production at night, which increases the final yield of the bioreactor.The Lighthouse is part of the Floriade 2022 permanent exhibition.',
            image:'lighthouse.webp'
        },
        5:{
            title:'Kaia',
            year: '2021',
            designer: 'Kim van den Belt',
            link: 'https://kimvandenbelt9.wixsite.com/mijnsite-1/kaia-the-living-co2-filter',
            text:'Kaia is a CO2 filter that takes the form of an elegant, transparent lamp, whose movement is reminiscent of a jellyfish bobbing in the water. Photosynthetic algae are the key component of the lamp. After a few months, it can even be harvested: with the biomass that is created, you can make biodiesel or even glass.',
            image:'kaia.webp'
        },
        6:{
            title:'Arboretum Digitalus',
            year: '2019',
            designer: 'Joshua Kelly',
            link: 'https://www.youtube.com/watch?v=Jqm-e5_P8cQ&ab_channel=ArboretumDigitalus',
            text:'Arboretum Digitalus launches “The Device”, a technology which utilizes a block-chain sensor network to calculate the amount of work a tree does and automatically generate an invoice to be paid for by the state. By drawing a small charge from the tree The Device can send out a beacon to say that is has ‘signed into work’. Once the tree has payed off its portion of the national debt it can become sovereign, and buy the piece of land the tree is rooted in.',
            image:'arboretum.webp'
        },
        7:{
            title:'The Luminarium',
            year: '2022',
            designer: '4F.STUDIO',
            link: 'https://the-luminarium.web.app/',
            text:'The Luminarium is an interactive learning experience aimed at teaching people about the wonder and beauty of Bioluminesence.',
            image:'luminarium.webp'
        },
        8:{
            title:'Light challenge pavillion Floriade',
            year: '2022',
            designer: '4F.STUDIO',
            link: 'none',
            text:'We created this installation to show the history and principles of the competition by using a light-weight and modular design from recyled and reusable materials. The large towering cages create a sense of the scale of the infrastructure we use to keep light captive.',
            image:'lightChallenge.webp'
        },
        9:{
            title:'Get in contact',
            text:"Our door is always open for a good cup of coffee and a chat",
            number: '+31 6 58859384',
            insta: '4f.studio',
            email: 'info@4f.studio',
        }
    },
    publications:{
        0:{
            title:'Collaboration for impact',
            link:'https://collaboration.forimpact.nl/projects/ck3'
        },
        1:{
            title:'BNO',
            link:'https://www.bno.nl/blog/artikelen-10/welkom-4f-studio-1556'
        },
        2:{
            title:'Next Nature Network : Retro Future',
            link:'https://nextnature.net/shop/products/retrofuture-magazine'
        },
        3:{
            title:'Elle Decor',
            link:'https://www.elledecor.com/it/design/a40200469/fuorisalone-2022-milano-base-nuovi-designer-ricercatori/'
        },
        4:{
            title:'Agro & Chemie',
            link:'https://www.agro-chemie.nl/artikelen/algen-en-wieren-gewassen-van-de-toekomst/'
        },
    }
}

export default data
import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import store from '../store/index.js';
import VueMeta from 'vue-meta'

Vue.use(VueMeta)
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    key:'home',
  },
  {
    path: '/about',
    name: 'About',
    key:'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/publications',
    name: 'Publications',
    key:'publications',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Publications.vue')
  },
  {
    path: '/exhibitions',
    name: 'Exhibitions',
    key:'Exhibitions',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Exhibitions.vue')
  },
  {
  path: '/lighthouse',
  name: 'Lighthouse',
  key:'lighthouse',
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "about" */ '../views/lighthouse.vue')
}
]



const router = new VueRouter({
  routes,
  mode: 'history'
})
router.beforeEach((to, from, next) => {
    window.scrollTo(0, 0)
    next()
  // ...
})
router.afterEach(() => {
  store.commit('setPanelState', false)
})
export default router

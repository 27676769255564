<template>
  <v-app class="colorB">
    <v-main>
      <transition name="fade" mode="out-in">
        <router-view></router-view>
      </transition>
    </v-main>
    <v-card
      color="white"
      width="100%"
      class="home elevation-0 pr-6 pl-5 py-8 d-flex align-center"
    >
      <a href="https://www.4f.studio"
        ><v-img
          max-height="40"
          max-width="40"
          :class="screenType"
          :src="require('@/assets/logo.jpg')"
        ></v-img
      ></a>
      <v-spacer />
      <v-col cols="auto" class="px-0" v-if="burger == false">
        <v-card-text>
          <router-link class="link" to="/">WORK</router-link>
        </v-card-text>
      </v-col>
      <v-col cols="auto" class="px-0" v-if="burger == false">
        <v-card-text>
          <router-link class="link" to="/about">ABOUT</router-link>
        </v-card-text>
      </v-col>
      <v-col
        cols="auto"
        class="px-0"
        @mouseover="isOpen = true"
        @mouseleave="isOpen = false"
        v-if="burger == false"
      >
        <v-card-text class="link"> PUBLICATIONS </v-card-text>
        <ul v-show="isOpen" class="dropdown">
          <li v-for="(child, index) in publications" :key="index">
            <a :href="child.link" target="_blank">
              {{ child.title }}
            </a>
          </li>
        </ul>
      </v-col>
      <v-col cols="auto" class="px-0" v-if="burger == false">
        <v-card-text>
          <router-link class="link" to="/exhibitions">EXHIBITIONS</router-link>
        </v-card-text>
      </v-col>
      <v-col cols="auto" v-if="burger == false" @click.stop="drawer = !drawer">
        <v-btn
          href="https://www.linkedin.com/company/4fstudio/"
          target="_blank"
          class="black--text"
          icon
        >
          <v-icon>mdi-linkedin</v-icon>
        </v-btn>
        <v-btn
          href="https://www.instagram.com/4f.studio/"
          target="_blank"
          class="black--text"
          icon
        >
          <v-icon>mdi-instagram</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="auto" v-if="burger">
        <v-btn class="black--text" icon @click.stop="drawer = !drawer">
          <v-icon>mdi-menu</v-icon>
        </v-btn>
      </v-col>
    </v-card>
    <v-navigation-drawer
      v-model="drawer"
      temporary
      right
      fixed
      height="100vh"
      v-if="burger"
    >
      <div class="topSpacer"></div>
      <v-list nav dense>
        <v-list-item-group
          v-model="group"
          active-class="deep-black--text text--accent-4"
        >
          <v-list-item>
            <v-card-text>
              <router-link class="mobile_link" to="/">WORK</router-link>
            </v-card-text>
          </v-list-item>

          <v-list-item>
            <v-card-text>
              <router-link class="mobile_link" to="/about">ABOUT</router-link>
            </v-card-text>
          </v-list-item>

          <v-list-item>
            <v-card-text>
              <router-link class="mobile_link" to="/publications"
                >PUBLICATIONS</router-link
              >
            </v-card-text>
          </v-list-item>
          <v-list-item>
            <v-card-text>
              <router-link class="mobile_link" to="/exhibitions"
                >EXHIBITIONS</router-link
              >
            </v-card-text>
          </v-list-item>

          <v-col cols="auto">
            <v-btn
              href="https://www.linkedin.com/company/4fstudio/"
              target="_blank"
              class="black--text"
              icon
            >
              <v-icon>mdi-linkedin</v-icon>
            </v-btn>
            <v-btn
              href="https://www.instagram.com/4f.studio/"
              target="_blank"
              class="black--text"
              icon
            >
              <v-icon>mdi-instagram</v-icon>
            </v-btn>
          </v-col>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </v-app>
</template>

<script>
import pageData from "@/data/data.js";
export default {
  name: "App",
  metaInfo: {
    title: "4F.STUDIO",
    titleTemplate: "%s | Nature Human",
    htmlAttrs: {
      lang: "en-US",
    },
    meta: [
      { charset: "utf-8" },
      {
        name: "description",
        vmid: "description",
        content:
          "4F.STUDIO is a future-thinking design practice that aims to combine nature and technology in a responsible way.",
      },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
    ],
  },
  data: () => ({
    isOpen: false,
    drawer: false,
    group: null,
  }),
  watch: {
    group() {
      this.drawer = false;
    },
  },
  computed: {
    screenType() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "mr-auto ml-2";
        case "sm":
          return "mr-auto ml-2";
        case "md":
          return "mr-auto ml-16";
        case "lg":
          return "mr-auto ml-16 ";
        case "xl":
          return "mr-auto ml-16";
      }
      return 6;
    },
    burger() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return false;
        case "lg":
          return false;
        case "xl":
          return false;
      }
      return false;
    },
    publications() {
      let publicationData = pageData["publications"];
      return publicationData;
    },
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.home {
  position: fixed;
  padding-top: 1em;
  padding-left: 2em;
  z-index: 100;
  color: black;
  max-height: 10%;
  overflow: hidden;
}
.svgObject {
  overflow-y: hidden;
  min-width: 100%;
  position: fixed;
  bottom: 0;
  right: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
.link {
  text-decoration: none;
  color: black;
  cursor: pointer;
}
.link:hover {
  text-decoration: underline;
}
.dropdown {
  position: fixed;
  list-style-type: none;
  background-color: #000000;
  padding: 0;
}

.dropdown li {
  width: auto;
  border-bottom: 1px solid #fff;
}

.dropdown li a {
  display: block;
  padding: 10px;
  color: #fff;
  text-decoration: none;
}
.dropdown li a:hover {
  text-decoration-color: red;
  text-decoration: underline;
}
.mobile_link {
  text-decoration: none;
  color: black;
  cursor: pointer;
}
.mobile_link:hover {
  text-decoration: underline;
}
.topSpacer {
  height: 15%;
}
</style>
<template>
  <div>
    <page-content :input-data="data"></page-content>
  </div>
</template>

<script>
// @ is an alias to /src
import pageContent from "../components/content.vue";

export default {
  name: "Home",
  metaInfo: {
    title: "4F.STUDIO",
    titleTemplate: "%s | Shaping reality",
    htmlAttrs: {
      lang: "en-US",
    },
    meta: [
      { charset: "utf-8" },
      {
        name: "description",
        vmid: "description",
        content:
          "4F.STUDIO creates installations, mixed-media sculptures, interactive experiences and sustainable products",
      },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
    ],
  },
  components: {
    pageContent,
  },
  data() {
    return {
      data: "work",
    };
  },
};
</script>

